import React, { useState, useEffect } from "react";
import api from "../../../api/api";

const EditForm = ({ formData, onSave, onCancel, token }) => {
  const initialFormData = {
    id: null,
    name: "",
    name_en: "",
    name_ar: "",
    sets: "",
    muscle: "",
    description_en: "",
    description_ar: "",
    images: [],
    videos: [], // Contains video objects
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formData) {
      console.warn("No formData provided!");
      return;
    }

    setFormValues({
      id: formData.id || null,
      name_en: formData.name_en || "",
      name_ar: formData.name_ar || "",
      sets: formData.sets || "",
      muscle: formData.muscle || "",
      description_en: formData.description_en || "",
      description_ar: formData.description_ar || "",
      images: formData.photos || [], // Use 'images' to align with the API
      videos: formData.videos || [],
    });

    const imagePreviews = (formData.photos || []).map((photo) => ({
      url: photo.url,
      preview: true,
    }));
    setPreviewImages(imagePreviews);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setFormValues((prevValues) => ({
      ...prevValues,
      images: files,
    }));

    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    setPreviewImages(previews);
  };

  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);

    setFormValues((prevValues) => ({
      ...prevValues,
      videos: files,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("id", formValues.id);
    data.append("name_en", formValues.name_en);
    data.append("name_ar", formValues.name_ar);
    data.append("sets", formValues.sets);
    data.append("muscle", formValues.muscle);
    data.append("description_en", formValues.description_en);
    data.append("description_ar", formValues.description_ar);

    formValues.images.forEach((image, index) => {
      if (image instanceof File) {
        data.append(`images[${index}]`, image);
      }
    });

    formValues.videos.forEach((video, index) => {
      if (video instanceof File) {
        data.append(`videos[${index}]`, video);
      }
    });

    try {
      setLoading(true);

      const response = await api.post(
        `/admin/exercises/${formValues.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSave(response.data);
    } catch (error) {
      // console.error("Error updating exercise:", error);
      alert(`Error updating exercise!`);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-10 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6 custom-scrollbar"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="name_en"
                  name="name_en"
                  type="text"
                  minLength="1"
                  maxLength="30"
                  defaultValue={formValues.name_en}
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="name_ar"
                  name="name_ar"
                  type="text"
                  minLength="1"
                  maxLength="30"
                  required
                  defaultValue={formValues.name_ar}
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Sets
                </h2>
                <input
                  id="sets"
                  name="sets"
                  type="number"
                  min="1"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Sets"
                  defaultValue={formValues.sets}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Exercise Type
                </h2>
                <input
                  id="muscle"
                  name="muscle"
                  type="text"
                  minLength="1"
                  maxLength="20"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Exercise Type"
                  defaultValue={formValues.muscle}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Description
              </h2>
              <textarea
                id="description_en"
                name="description_en"
                rows="4"
                required
                defaultValue={formValues.description_en}
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Description
              </h2>
              <textarea
                id="description_ar"
                name="description_ar"
                rows="4"
                required
                defaultValue={formValues.description_ar}
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Photos
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={handleImageChange}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Video
              </h2>
              <input
                id="videos"
                name="videos"
                type="file"
                multiple
                accept="video/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={handleVideoChange}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4 pr-5">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
