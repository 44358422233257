import React, { useState, useEffect } from "react";
import api from "../../../../api/api";
import DatePicker from "react-multi-date-picker";
// import { useParams } from "react-router-dom";
import Modal from "../../../../UI/Modal/Modal";

const AddMeals = ({ CloseMealsSchedule, setShouldFetch, formData }) => {
  //   const { id } = useParams();
  const initialFormData = {
    user_ids: [],
    meal_ids: "", // Single meal ID
    dates: "", // Single date
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [meals, setMeals] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null); // Single date
  const [mealSearch, setMealSearch] = useState(""); // State for meal search
  const [loading, setLoading] = useState(false);
  const [loadingMeals, setLoadingMeals] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchMeals();
  }, []);

  const fetchMeals = async () => {
    setLoadingMeals(true);
    try {
      const response = await api.get(
        "/admin/meals-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeals(response.data.data);
    } catch (error) {
      alert("Error fetching meals");
    } finally {
      setLoadingMeals(false);
    }
  };

  const handleMealChange = (id) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      meal_ids: id, // Only one meal ID
    }));
  };

  const handleDateChange = (date) => {
    const dateString = date.format("YYYY-MM-DD");
    setSelectedDate(date);
    setFormValues({
      ...formValues,
      dates: dateString, // Only one date
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.meal_ids) {
      alert("Please select a meal.");
      return;
    }
    if (!formValues.dates) {
      alert("Please select a date.");
      return;
    }

    const payload = {
      user_meal_id: formData.pivot.id,
      meal_id: formValues.meal_ids,
      meal_date: formValues.dates,
    };

    // console.log(payload);

    try {
      setLoading(true);
      const response = await api.post("/admin/user-meals", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      CloseMealsSchedule();
      setShouldFetch(true);
    } catch (error) {
      // console.error("Error assigning meals:", error);
      alert(`Error assigning meals`);
    }
    setLoading(false);
  };

  const filteredMeals = meals.filter((meal) =>
    meal.title.toLowerCase().includes(mealSearch.toLowerCase())
  );

  return (
    <Modal>
      <div className="bg-[#252525] py-5 w-[92%] mx-auto">
        <form
          className="max-h-[80vh] overflow-y-auto gap-y-6"
          onSubmit={handleSubmit}
        >
          <div className="">
            <div className="pr-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Meals
              </h2>
              <input
                type="text"
                placeholder="Search Meals"
                className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={mealSearch}
                onChange={(e) => setMealSearch(e.target.value)}
              />
              <div className="max-h-40 overflow-y-auto space-y-2">
                {loadingMeals && <p className="text-white py-2">Loading...</p>}
                {filteredMeals.map((meal) => (
                  <label
                    key={meal.id}
                    className="flex items-center text-white gap-x-3"
                  >
                    <input
                      type="radio"
                      checked={formValues.meal_ids === meal.id}
                      onChange={() => handleMealChange(meal.id)}
                      className="form-radio h-5 w-5 text-blue-600"
                    />
                    <span>
                      {meal.title} ( {meal.meal_type[0]} )
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="rounded-md shadow-sm gap-y-4 mt-6">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Select Date
              </h2>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                required
              />
            </div>
          </div>
          <div className="flex justify-end gap-x-4 mt-6">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={CloseMealsSchedule}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddMeals;
