import React, { useState } from "react";
import api from "../../../api/api";

const AddForm = ({ onSave, onCancel, setShouldFetch }) => {
  const initialFormData = {
    id: null,
    name: "",
    name_en: "",
    name_ar: "",
    sets: "",
    muscle: "",
    description_en: "",
    description_ar: "",
    images: [],
    videos: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [previewVideos, setPreviewVideos] = useState([]);

  const token = localStorage.getItem("authenticatedToken");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    // Update formValues with new files
    setFormData((prevValues) => ({
      ...prevValues,
      [type]: files,
    }));

    // Generate previews for newly added files
    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    if (type === "images") {
      setPreviewImages(previews);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name_en", formData.name_en);
    formDataToSend.append("name_ar", formData.name_ar);
    formDataToSend.append("sets", formData.sets);
    formDataToSend.append("muscle", formData.muscle);
    formDataToSend.append("description_en", formData.description_en);
    formDataToSend.append("description_ar", formData.description_ar);

    // Append images to formData
    formData.images.forEach((file, index) => {
      if (file instanceof File) {
        formDataToSend.append(`images[${index}]`, file);
      }
    });

    // Append videos to formData
    formData.videos.forEach((file, index) => {
      if (file instanceof File) {
        formDataToSend.append(`videos[${index}]`, file);
      }
    });

    try {
      setLoading(true);
      const response = await api.post("/admin/exercises", formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      onSave(response.data);
      // Reset form and previews after successful save
      setFormData(initialFormData);
      setPreviewImages([]);
      setShouldFetch(true);
    } catch (error) {
      // console.error("Error adding exercise:", error);
      alert(`Error adding exercise`);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-12 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6 custom-scrollbar"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="name_en"
                  name="name_en"
                  type="text"
                  minLength="1"
                  maxLength="30"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="name_ar"
                  name="name_ar"
                  type="text"
                  minLength="1"
                  maxLength="30"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Sets
                </h2>
                <input
                  id="sets"
                  name="sets"
                  type="number"
                  min="1"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Sets"
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Exercise Type
                </h2>
                <input
                  id="muscle"
                  name="muscle"
                  type="text"
                  minLength="1"
                  maxLength="20"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Exercise Type"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Description
              </h2>
              <textarea
                id="description_en"
                name="description_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Description
              </h2>
              <textarea
                id="description_ar"
                name="description_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Images
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "images")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Video
              </h2>
              <input
                id="videos"
                name="videos"
                type="file"
                multiple
                accept="video/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "videos")}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4 pr-5">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none "
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
