import React, { useState, useEffect } from "react";
import api from "../../../api/api";

const AddForm = ({ onSave, onCancel }) => {
  const initialFormData = {
    id: null,
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    tags: [],
    exercise_ids: [],
    images: [],
    // videos: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);
  // const [previewVideos, setPreviewVideos] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [exerciseSearch, setExerciseSearch] = useState(""); // State for user search
  const [loading, setLoading] = useState(false);
  const [loadingWorkouts, setLoadingWorkouts] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchExercises();
  }, []);

  const fetchExercises = async () => {
    setLoadingWorkouts(true);
    try {
      const response = await api.get(
        "/admin/exercises-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data);
      setExercises(response.data.data);
    } catch (error) {
      alert("Error fetching exercises");
    } finally {
      setLoadingWorkouts(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("tag_")) {
      const index = parseInt(name.split("_")[1]);
      const newTags = [...formValues.tags];
      newTags[index] = value;
      setFormValues({ ...formValues, tags: newTags });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleAddTag = () => {
    setFormValues({
      ...formValues,
      tags: [...formValues.tags, ""],
    });
  };

  const handleRemoveTag = (index) => {
    const newTags = formValues.tags.filter((_, i) => i !== index);
    setFormValues({ ...formValues, tags: newTags });
  };

  const handleExerciseChange = (id) => {
    // Toggle selection of user
    setFormValues((prevValues) => {
      const exercise_ids = prevValues.exercise_ids.includes(id)
        ? prevValues.exercise_ids.filter((exercise_id) => exercise_id !== id)
        : [...prevValues.exercise_ids, id];
      return { ...prevValues, exercise_ids };
    });
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    // Update formValues with new files
    setFormValues((prevValues) => ({
      ...prevValues,
      [type]: files,
    }));

    // Generate previews for newly added files
    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    if (type === "images") {
      setPreviewImages(previews);
    }
    // else if (type === "videos") {
    //   setPreviewVideos(previews);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedExercises.length === 0) {
      alert("you should selecte Exercises");
    } else {
      try {
        setLoading(true);

        await onSave({
          ...formValues,
          tags: formValues.tags.filter(
            (tag) => typeof tag === "string" && tag.trim() !== ""
          ),
        });
        // console.log(formValues);
        setPreviewImages([]);
        // setPreviewVideos([]);
        // console.log("Meal updated successfully");
      } catch (error) {
        // console.error("Error updating workout:", error);
        alert(`Error updating workout`);
      }
      setLoading(false);
    }
  };

  // Filter and sort users based on search query and selection status
  const filteredExercises = exercises
    .filter((exercise) =>
      exercise.name.toLowerCase().includes(exerciseSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.exercise_ids.includes(a.id);
      const bSelected = formValues.exercise_ids.includes(b.id);
      return bSelected - aSelected;
    });
  // Separate selected and unselected users
  const selectedExercises = filteredExercises.filter((exercise) =>
    formValues.exercise_ids.includes(exercise.id)
  );
  const unselectedExercises = filteredExercises.filter(
    (exercise) => !formValues.exercise_ids.includes(exercise.id)
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-12 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6 custom-scrollbar"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="title_en"
                  name="title_en"
                  type="text"
                  minLength="2"
                  maxLength="30"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formValues.title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="title_ar"
                  name="title_ar"
                  type="text"
                  minLength="2"
                  maxLength="30"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formValues.title_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Tags
              </h2>
              {formValues.tags.map((tag, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`tag_${index}`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Tag Name"
                    defaultValue={tag}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveTag(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddTag}
              >
                Add Tag
              </button>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Description
              </h2>
              <textarea
                id="description_en"
                name="description_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.description_en}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Description
              </h2>
              <textarea
                id="description_ar"
                name="description_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.description_ar}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2 pr-2">
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Exercises
                </h2>
                <input
                  type="text"
                  placeholder="Search Exercises"
                  className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  defaultValue={exerciseSearch}
                  onChange={(e) => setExerciseSearch(e.target.value)}
                />
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {loadingWorkouts && (
                    <p className="text-white py-2">Loading...</p>
                  )}
                  {unselectedExercises.map((exercise) => (
                    <label
                      key={exercise.id}
                      className="flex items-center text-white space-x-3"
                    >
                      <input
                        type="checkbox"
                        checked={formValues.exercise_ids.includes(exercise.id)}
                        onChange={() => handleExerciseChange(exercise.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>{exercise.name}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="w-1/2 pl-2">
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Selected Exercises ({selectedExercises.length})
                </h2>
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {selectedExercises.map((exercise) => (
                    <label
                      key={exercise.id}
                      className="flex items-center text-white space-x-3"
                    >
                      <input
                        type="checkbox"
                        checked={formValues.exercise_ids.includes(exercise.id)}
                        onChange={() => handleExerciseChange(exercise.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>{exercise.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Images
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "images")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mx-5 flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
