import React, { useState } from "react";
import api from "../../../api/api";
import Table from "../../../components/Table/Table";

const NotificationsDone = ({ filteredNotifications, setUpdate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const token = localStorage.getItem("authenticatedToken");

  const deleteNotification = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Do you want to delete this notification?"
      );
      if (!isConfirmed) {
        return;
      }

      await api.delete(`/admin/notifications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUpdate(true);
    } catch (error) {
      // console.error("Error deleting notification:", error);
      alert(`Error deleting notification`);
    }
  };

  // Format date to a more readable format
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredNotifications.length / itemsPerPage);

  // Get the notifications to display on the current page
  const paginatedNotifications = filteredNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const headers = ["Title", "Description", "Link", "Read At", "User"];

  const renderRow = (notification) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 max-w-36 overflow-hidden">
        {notification.title || ""}
      </td>
      <td className="max-w-[35px] break-words overflow-hidden text-ellipsis px-6 py-4 text-sm text-gray-500">
        {notification.body || ""}
      </td>
      <td className="max-w-[35px] break-words overflow-hidden text-ellipsis px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {notification.link || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {notification.read_at ? formatDate(notification.read_at) : "Not Yet"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {notification.user.name || ""}
      </td>
    </>
  );

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <Table
          headers={headers}
          data={paginatedNotifications}
          onDelete={deleteNotification}
          renderRow={renderRow}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default NotificationsDone;
