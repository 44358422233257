import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppStore from "../assets/images/App Store.svg";
import DownloadApk from "../assets/images/Download-APK.svg";
import GooglePlay from "../assets/images/Google Play.png";
import webVersion from "../assets/images/webVersion.svg";

const APKLINK = process.env.REACT_APP_API_URL_APK;
const APPLELINK = process.env.REACT_APP_API_URL_APPLE;

const PricingCard = ({
  name,
  price,
  old_price,
  features_detail,
  plan_type,
  description,
  invoice_period,
  syrianPrice,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();
  const perDay = parseFloat((price / (invoice_period * 30)).toFixed(2));
  const { t, i18n } = useTranslation();

  const isArabic = i18n.language === "en";

  // Function to handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className="relative p-4 md:p-6 bg-[#1C1B1E] rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:border-orange-500 border-4 border-transparent w-[92%] md:w-96">
      <div className="absolute w-[calc(100%+8px)] rounded-t-lg h-14 -top-1 -left-1 bg-orange-500 text-white text-2xl font-bold pt-2 text-center">
        {plan_type === "Most Popular" ? t("mostPopular") : t("standard")}
      </div>

      <div className="text-center pt-3">
        <h3 className="text-white text-xl md:text-3xl font-semibold mt-12">
          {name}
        </h3>
        <h3 className="text-white text-base mt-2">{description}</h3>

        <p className="text-orange-400 text-xl mt-2 md:mt-4">
          {features_detail}
        </p>

        <div className="flex items-start justify-center text-white mt-4 opacity-50">
          <sup className="text-sm">{syrianPrice ? "$" : "SYR"}</sup>
          <span className="text-xl md:text-4xl line-through">{old_price}</span>
        </div>

        <div className="flex items-start justify-center text-white font-bold my-3 md:my-6">
          <sup className="text-base">{syrianPrice ? "$" : "SYR"}</sup>
          <span className="text-3xl md:text-[55px] leading-[3rem]">
            {price}
          </span>
        </div>
        <p className="text-orange-400 text-lg md:text-xl">
          {syrianPrice ? "$" : "SYR"} {perDay} {t("perDay")}
        </p>
        <button
          className="bg-orange-500 font-semibold text-white w-3/4  text-[20px] py-2 md:py-3 mt-5 md:mt-10 mb-2 rounded-3xl"
          onClick={() => setIsModalOpen(true)}
        >
          {t("subscribe")}
        </button>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
          {/* Modal Content */}
          <div
            className="bg-orange-500 p-6 w-11/12 rounded-lg shadow-lg flex flex-col gap-y-8 items-center mx-auto"
            ref={modalRef}
          >
            <div>
              <a
                href={APKLINK}
                target="_blank"
                rel="noopener noreferrer"
                className="focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <img
                  src={DownloadApk}
                  className=" transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                  alt="Google Play"
                />
              </a>
            </div>
            <div>
              <a
                href={APPLELINK}
                target="_blank"
                rel="noopener noreferrer"
                className="focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <img
                  src={webVersion}
                  className="bg-white rounded-xl transform transition-transform duration-200 hover:scale-105 hover:shadow-lg"
                  alt="App Store"
                />
              </a>
              <p dir={`${isArabic ? "" : "rtl"}`}>*{t("comingSoon")}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
