import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import Loader from "../../../components/Loader/Loader";
import Table from "../../../components/Table/Table";
import EditSettings from "./EditSettings";
import PlanManagement from "./Plans/PlanManagement";

const SettingsManagement = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchSettings();
  }, [token]);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/settings?paginate=100", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSettings(response.data.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
      // alert(`Error fetching settings!`);
    } finally {
      setLoading(false);
    }
  };

  const headers = ["Name", "Values"];

  const renderRow = (setting) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {setting.type || ""}
      </td>
      <td className="px-4 py-2">
        {Array.isArray(setting.value) ? (
          setting.value_en.map((v, index) => (
            <div key={index}>
              <span className="font-semibold">{v || "No Value"}</span>
            </div>
          ))
        ) : (
          <div>
            {typeof setting.value === "object" ? (
              <>
                <span className="block my-2 font-semibold">{`Account Number: ${
                  setting.value.account_number || ""
                }`}</span>
                <span className="block my-2 font-semibold">{`Name: ${
                  setting.value.account_name || ""
                }`}</span>
                <span className="block my-2 font-semibold">{`Phone: ${
                  setting.value.phone_number || ""
                }`}</span>
              </>
            ) : (
              <span className="font-semibold">
                {setting.value || "No Value"}
              </span>
            )}
          </div>
        )}
        {Array.isArray(setting.photos) && setting.photos.length > 0 && (
          <div className="mt-4">
            {setting.photos.map((photo, index) => (
              <img
                key={index}
                src={photo.url}
                alt={photo.name}
                className="w-24 h-24 object-cover"
              />
            ))}
          </div>
        )}
      </td>
    </>
  );

  const handleEditClick = (setting) => {
    setSelectedSetting(setting);
    setShowEditForm(true);
  };

  return (
    <>
      <PlanManagement />
      <div className="p-8 border rounded shadow overflow-x-auto">
        <div className="sm:flex sm:items-center mb-10">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">
              Settings Dashboard
            </h1>
          </div>
        </div>
        {showEditForm && (
          <EditSettings
            onCancel={() => setShowEditForm(false)}
            token={token}
            formData={selectedSetting}
            refresh={() => fetchSettings()}
          />
        )}
        {loading ? (
          <Loader />
        ) : (
          <Table
            headers={headers}
            data={settings}
            onEdit={handleEditClick}
            renderRow={renderRow}
          />
        )}
      </div>
    </>
  );
};

export default SettingsManagement;
