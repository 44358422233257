import React, { useState, useEffect } from "react";
import api from "../../../api/api";

const AddRoutines = ({ CloseRoutinesSchedule }) => {
  const initialFormData = {
    user_ids: [],
    routine_ids: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [routines, setRoutines] = useState([]);
  const [users, setUsers] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [routineSearch, setRoutineSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRoutines, setLoadingRoutines] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchRoutines();
    fetchUsers();
  }, []);

  const fetchRoutines = async () => {
    setLoadingRoutines(true);
    try {
      const response = await api.get(
        "/admin/routines-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRoutines(response.data.data);
    } catch (error) {
      alert("Error fetching Routines");
    } finally {
      setLoadingRoutines(false);
    }
  };

  const fetchUsers = async () => {
    setLoadingUsers(true);
    try {
      const response = await api.get(
        "/admin/users-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.data);
    } catch (error) {
      alert("Error fetching users");
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleRoutineChange = (id) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      routine_ids: [id], // Only allow one routine ID
    }));
  };

  const handleUserChange = (id) => {
    setFormValues((prevValues) => {
      const user_ids = prevValues.user_ids.includes(id)
        ? prevValues.user_ids.filter((user_id) => user_id !== id)
        : [...prevValues.user_ids, id];
      return { ...prevValues, user_ids };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure at least one user, one routine, and start date are selected
    if (formValues.user_ids.length === 0) {
      alert("Please select at least one user.");
      return;
    }
    if (formValues.routine_ids.length === 0) {
      alert("Please select at least one routine.");
      return;
    }

    const payload = {
      user_ids: formValues.user_ids,
      routine_ids: formValues.routine_ids,
    };

    try {
      setLoading(true);

      const response = await api.post("/admin/routines/users/bulk", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      CloseRoutinesSchedule();
    } catch (error) {
      // console.error("Error assigning routine:", error);
      alert(`Error assigning routine`);
    }
    setLoading(false);
  };

  const filteredUsers = users
    .filter((user) =>
      user.name.toLowerCase().includes(userSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.user_ids.includes(a.id);
      const bSelected = formValues.user_ids.includes(b.id);
      return bSelected - aSelected;
    });

  const filteredRoutines = routines
    .filter((routine) =>
      routine.name.toLowerCase().includes(routineSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.routine_ids.includes(a.id);
      const bSelected = formValues.routine_ids.includes(b.id);
      return bSelected - aSelected;
    });

  const selectedUsers = filteredUsers.filter((user) =>
    formValues.user_ids.includes(user.id)
  );
  const unselectedUsers = filteredUsers.filter(
    (user) => !formValues.user_ids.includes(user.id)
  );

  const selectedRoutines = filteredRoutines.filter((routine) =>
    formValues.routine_ids.includes(routine.id)
  );
  const unselectedRoutines = filteredRoutines.filter(
    (routine) => !formValues.routine_ids.includes(routine.id)
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-12 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6 custom-scrollbar"
          onSubmit={handleSubmit}
        >
          <div className="flex space-x-4">
            <div className="w-1/2 pr-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Users
              </h2>
              <input
                type="text"
                placeholder="Search Users"
                className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />
              <div className="max-h-40 overflow-y-auto space-y-2">
                {loadingUsers && <p className="text-white py-2">Loading...</p>}
                {unselectedUsers.map((user) => (
                  <label
                    key={user.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.user_ids.includes(user.id)}
                      onChange={() => handleUserChange(user.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{user.name}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Selected Users ({selectedUsers.length})
              </h2>
              <div className="max-h-40 overflow-y-auto space-y-2">
                {selectedUsers.map((user) => (
                  <label
                    key={user.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.user_ids.includes(user.id)}
                      onChange={() => handleUserChange(user.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{user.name}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="flex space-x-4 mt-6">
            <div className="w-1/2 pr-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Routines
              </h2>
              <input
                type="text"
                placeholder="Search Routines"
                className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={routineSearch}
                onChange={(e) => setRoutineSearch(e.target.value)}
              />
              <div className="max-h-40 overflow-y-auto space-y-2">
                {loadingRoutines && (
                  <p className="text-white py-2">Loading...</p>
                )}
                {unselectedRoutines.map((routine) => (
                  <label
                    key={routine.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="radio"
                      name="routine"
                      defaultValue={routine.id}
                      checked={formValues.routine_ids.includes(routine.id)}
                      onChange={() => handleRoutineChange(routine.id)}
                      className="form-radio h-5 w-5 text-blue-600"
                    />
                    <span>{routine.name}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Selected Routines ({selectedRoutines.length})
              </h2>
              <div className="max-h-40 overflow-y-auto space-y-2">
                {selectedRoutines.map((routine) => (
                  <label
                    key={routine.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="radio"
                      name="routine"
                      checked={formValues.routine_ids.includes(routine.id)}
                      onChange={() => handleRoutineChange(routine.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{routine.name}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-end mx-5 space-x-4 mt-6">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={CloseRoutinesSchedule}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRoutines;
