import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Pagination from "../Pagination"; // Assuming you have a Pagination component
import { v4 as uuidv4 } from "uuid";

const Table = ({
  headers,
  data,
  onEdit,
  onDelete,
  renderRow,
  currentPage,
  totalPages,
  onPageChange,
  pivotId,
}) => {
  return (
    <div className="w-auto py-2 align-middle inline-block sm:px-6 lg:px-8">
      <div className="shadow bg-gray-50 overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <div className="overflow-x-auto">
          {/* Enable horizontal scrolling for the table */}
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {headers.map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
                {(onEdit || onDelete) && (
                  <th scope="col" className="px-6 py-3">
                    <span className="text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data && data.length > 0 ? (
                data.map((item) => (
                  <tr key={uuidv4()}>
                    {renderRow(item)}
                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                      {onEdit && (
                        <button
                          type="button"
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => onEdit(item)}
                        >
                          <FaEdit />
                        </button>
                      )}
                      {onDelete && (
                        <button
                          type="button"
                          className="text-red-600 hover:text-red-900 ml-4"
                          onClick={() =>
                            onDelete(pivotId ? item.pivot.id : item.id)
                          }
                        >
                          <FaTrash />
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={headers.length + 1}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination Controls */}
      <div className="my-4 overflow-x-auto">
        {/* Enable horizontal scrolling for pagination */}
        {currentPage && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
