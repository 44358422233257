import React, { useState, useEffect, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import api from "../../../api/api";

const Chart = () => {
  const token = localStorage.getItem("authenticatedToken");

  // State to toggle between age and country
  const [showUserAge, setShowUserAge] = useState(true);

  // State for chart data
  const [ageData, setAgeData] = useState({ categories: [], data: [] });
  const [countryData, setCountryData] = useState({ categories: [], data: [] });

  const [chartOptions, setChartOptions] = useState({
    colors: ["#3C50E0", "#80CAEE"],
    chart: {
      fontFamily: "Satoshi, sans-serif",
      type: "bar",
      height: 335,
      stacked: true,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    responsive: [
      {
        breakpoint: 1536,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: "25%",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: "25%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: [
        "0 - 18",
        "19 - 25",
        "26 - 32",
        "33 - 39",
        "40 - 46",
        "47 - 50",
        "50+",
      ],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontFamily: "Satoshi",
      fontWeight: 500,
      fontSize: "14px",
      markers: { radius: 99 },
    },
    fill: { opacity: 1 },
  });

  const fetchData = useCallback(
    async (url, isAgeData) => {
      try {
        const response = await api.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = response.data.data;

        if (isAgeData) {
          const ageCategories = [
            "0 - 18",
            "19 - 25",
            "26 - 32",
            "33 - 39",
            "40 - 46",
            "47 - 50",
            "50+",
          ];
          setAgeData({
            categories: ageCategories,
            data: [
              data["0-18"],
              data["19-25"],
              data["26-32"],
              data["33-39"],
              data["40-46"],
              data["47-50"],
              data["50+"],
            ],
          });
        } else {
          const countryNames = Object.keys(data);
          const countryData = Object.values(data);
          setCountryData({
            categories: countryNames,
            data: countryData,
          });
        }
      } catch (error) {
        console.log("Error fetching data");
        // alert("Error fetching data");
      }
    },
    [token]
  );

  useEffect(() => {
    fetchData("/admin/reports/users-by-age-group", true);
    fetchData("/admin/reports/users-by-country", false);
  }, [fetchData]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setShowUserAge(selectedValue === "age");

    if (selectedValue === "age") {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories: ageData.categories },
      }));
    } else {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories: countryData.categories },
      }));
    }
  };

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-4  shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
      <div className="mb-4 justify-between gap-4 sm:flex">
        <div>
          <h4 className="text-xl font-semibold text-black">User Info</h4>
        </div>
        <div>
          <div className="relative z-20 inline-block">
            <select
              name="userDemographics"
              className="relative z-20 text-center inline-flex border-solid border-[1px] border-black rounded-xl appearance-none bg-transparent p-2 text-sm font-medium outline-none cursor-pointer"
              onChange={handleSelectChange}
            >
              <option value="age" className="dark:bg-boxdark cursor-pointer">
                User By Age
              </option>
              <option
                value="country"
                className="dark:bg-boxdark cursor-pointer"
              >
                User By Country
              </option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div className="-ml-5">
          <ReactApexChart
            options={chartOptions}
            series={[
              {
                name: showUserAge ? "Users By Age" : "Users By Country",
                data: showUserAge ? ageData.data : countryData.data,
              },
            ]}
            type="bar"
            height={335}
          />
        </div>
      </div>
    </div>
  );
};

export default Chart;
