import api from "../../../api/api";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const options = {
  chart: {
    fontFamily: "Satoshi, sans-serif",
    type: "donut",
  },
  colors: ["#3C50E0", "#8FD0EF", "#0FAD90", "#6577F3"],
  legend: {
    show: false,
    position: "bottom",
  },
  plotOptions: {
    pie: {
      donut: {
        size: "65%",
        background: "transparent",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const ChartCircle = () => {
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await api.get("/admin/reports/subscription", {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        const data = response.data.data;

        // Extract labels and data dynamically
        const labels = data.map((item) => item.plan_name);
        const series = data.map((item) => item.percentage);

        setChartLabels(labels);
        setChartData(series);
      } catch (error) {
        console.log("Error fetching subscription data");
        // alert("Error fetching subscription data");
      }
    };

    fetchStatus();
  }, [token]);

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-4 shadow-default xl:col-span-5">
      <div className="mb-3 justify-between gap-4 sm:flex">
        <p className="text-xl font-semibold text-black">
          Subscription Analytics
        </p>
      </div>

      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart
            options={{ ...options, labels: chartLabels }}
            series={chartData}
            type="donut"
          />
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3">
        {chartLabels.map((label, index) => (
          <div key={index} className="px-2">
            <div className="flex w-full items-center">
              <span
                className="mr-2 block h-3 w-3 rounded-full"
                style={{
                  backgroundColor:
                    options.colors[index % options.colors.length],
                }}
              ></span>
              <p className="flex gap-x-4 text-sm font-medium text-black">
                <span>{label}:</span>
                <span>{chartData[index]}%</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartCircle;
