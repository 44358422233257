import React, { useState, useEffect, useMemo } from "react";
import api from "../../../api/api";
import AddRoutines from "./AddRoutines";
import AddMeals from "./AddMeals";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import Table from "../../../components/Table/Table";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showRoutinesSchedule, setShowRoutinesSchedule] = useState(false);
  const [showMealsSchedule, setShowMealsSchedule] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState(
    () => localStorage.getItem("sortOrder") || "asc"
  );
  const [filterOrder, setFilterOrder] = useState(
    () => localStorage.getItem("filterOrder") || "allUsers"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 20;
  const navigate = useNavigate();
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchUsers();
  }, []);

  // Save sortOrder and filterOrder to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("sortOrder", sortOrder);
  }, [sortOrder]);

  useEffect(() => {
    localStorage.setItem("filterOrder", filterOrder);
  }, [filterOrder]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/users?paginate=2000", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.log("Error fetching users!");
    }
    setLoading(false);
  };

  const deleteUser = async (id) => {
    try {
      const isConfirmed = window.confirm("Do you want to delete this user?");
      if (!isConfirmed) {
        return;
      }

      await api.delete(`/admin/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
    } catch (error) {
      alert("Error deleting user!");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilterOrder(e.target.value);
    setCurrentPage(1);
  };

  const getFilteredAndSortedUsers = useMemo(() => {
    const filtered = users
      .filter((user) =>
        user.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .filter((user) => {
        if (filterOrder === "activeUsers") {
          return user.has_active_subscription;
        }
        if (filterOrder === "unactiveUsers") {
          return !user.has_active_subscription;
        }
        if (filterOrder === "planPrep") {
          if (user.has_active_subscription) {
            return !user.has_meals_for_today || !user.has_workouts_for_today;
          } else {
            return false;
          }
        }
        if (filterOrder === "activationRequired") {
          return (
            !user.has_active_subscription && user.payment_receipt.length > 0
          );
        }
        return true;
      });

    const sorted = filtered.sort((a, b) => {
      if (sortOrder === "asc") return a.name.localeCompare(b.name);
      if (sortOrder === "desc") return b.name.localeCompare(a.name);
      if (sortOrder === "oldest")
        return new Date(a.created_at) - new Date(b.created_at);
      if (sortOrder === "newest")
        return new Date(b.created_at) - new Date(a.created_at);
      return 0;
    });

    return sorted;
  }, [users, searchQuery, filterOrder, sortOrder]);

  const currentUsers = useMemo(() => {
    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    return getFilteredAndSortedUsers.slice(indexOfFirstUser, indexOfLastUser);
  }, [currentPage, itemsPerPage, getFilteredAndSortedUsers]);

  const totalPages = Math.ceil(getFilteredAndSortedUsers.length / itemsPerPage);

  const headers = [
    "Name",
    "Email",
    "Phone",
    "Sex",
    "Weight (Kg)",
    "Height (cm)",
    "Birth Date",
  ];

  const renderRow = (user) => (
    <>
      <td
        className="px-6 py-4 text-sm font-medium text-gray-900"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.name || ""}
      </td>
      <td
        className="px-6 py-4 text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.email || ""}
      </td>
      <td
        className="px-6 py-4 text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.phone || ""}
      </td>
      <td
        className="px-6 py-4 text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.sex || ""}
      </td>
      <td
        className="px-6 py-4 text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.weight || ""}
      </td>
      <td
        className="px-6 py-4 text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.height || ""}
      </td>
      <td
        className="px-6 py-4 text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.birth_date || ""}
      </td>
    </>
  );

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-semibold text-gray-900">Users Dashboard</h1>

      {showRoutinesSchedule && (
        <AddRoutines
          CloseRoutinesSchedule={() => setShowRoutinesSchedule(false)}
        />
      )}
      {showMealsSchedule && (
        <AddMeals CloseMealsSchedule={() => setShowMealsSchedule(false)} />
      )}

      <div className="mt-8 mb-6 flex flex-col md:space-x-6 md:flex-row">
        <div className="flex w-[60%] gap-x-6">
          <input
            type="text"
            placeholder="Search by Name"
            className="flex-grow mb-4 block px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <select
            className="flex-grow mb-4 block px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={sortOrder}
            onChange={handleSortChange}
          >
            <option value="asc">Name (A-Z)</option>
            <option value="desc">Name (Z-A)</option>
            <option value="oldest">Oldest</option>
            <option value="newest">Newest</option>
          </select>

          <select
            className="flex-grow mb-4 block px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={filterOrder}
            onChange={handleFilterChange}
          >
            <option value="allUsers">All Users</option>
            <option value="activeUsers">Active Users</option>
            <option value="unactiveUsers">Unactive Users</option>
            <option value="planPrep">Plan Prep</option>
            <option value="activationRequired">Activation Required</option>
          </select>
        </div>

        <div className="flex space-x-8 flex-row justify-around">
          <button
            className="inline-flex items-center h-12 px-4 border rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
            onClick={() => setShowRoutinesSchedule(true)}
          >
            <FaPlusCircle className="mr-2" /> Routines Schedule
          </button>
          <button
            className="inline-flex items-center h-12 px-4 border rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
            onClick={() => setShowMealsSchedule(true)}
          >
            <FaPlusCircle className="mr-2" /> Meals Schedule
          </button>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <Table
          headers={headers}
          data={currentUsers}
          onDelete={deleteUser}
          renderRow={renderRow}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Users;
