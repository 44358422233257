import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import { FaPlusCircle } from "react-icons/fa";
import EditForm from "./EditForm";
import AddForm from "./AddForm";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader/Loader";
import Table from "../../../components/Table/Table";

const Exercises = () => {
  const [exercises, setExercises] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentExercise, setCurrentExercise] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 20;
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    const fetchData = async () => {
      if (shouldFetch || exercises.length === 0) {
        await fetchExercises();
        setShouldFetch(false);
      }
    };

    fetchData();
  }, [shouldFetch]);

  const fetchExercises = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/exercises?paginate=1000", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      setExercises(response.data.data);
    } catch (error) {
      console.log("Error fetching exercises!");
      // alert("Error fetching exercises!");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    setShowAddForm(false);
  };

  const deleteExercise = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Do you want to delete this exercise?"
      );
      if (!isConfirmed) {
        return;
      }

      await api.delete(`/admin/exercises/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShouldFetch(true);
    } catch (error) {
      // console.error("Error deleting exercise:", error);
      alert(`Error deleting exercise`);
    }
  };

  const handleEditClick = (exercise) => {
    setCurrentExercise(exercise);
    setShowEditForm(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentExercise({ ...currentExercise, [name]: value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const getSortedExercises = (exercises) => {
    let sortedExercises = [...exercises];

    if (sortOrder === "asc") {
      sortedExercises.sort((a, b) =>
        (a.name || "").localeCompare(b.name || "")
      );
    } else if (sortOrder === "desc") {
      sortedExercises.sort((a, b) =>
        (b.name || "").localeCompare(a.name || "")
      );
    } else if (sortOrder === "oldest") {
      sortedExercises.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedExercises.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedExercises;
  };

  const filteredExercises = getSortedExercises(
    exercises.filter((exercise) => {
      if (exercise && exercise.name && typeof exercise.name === "string") {
        return exercise.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredExercises.length / itemsPerPage);
  const currentExercises = filteredExercises.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const headers = ["Name", "Exercise Type", "Sets Number", "Description"];

  const renderRow = (exercise) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {exercise.name_en || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {exercise.muscle || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {exercise.sets || ""}
      </td>
      <td className="max-w-[35px] break-words overflow-hidden text-ellipsis px-6 py-4 text-sm text-gray-500">
        {exercise.description_en || ""}
      </td>
    </>
  );

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Exercise Dashboard
          </h1>
        </div>
      </div>

      {showAddForm && (
        <AddForm
          onSave={handleSave}
          setShouldFetch={setShouldFetch}
          onCancel={() => setShowAddForm(false)}
        />
      )}
      {showEditForm && (
        <EditForm
          formData={currentExercise}
          onSave={(updatedExercise) => {
            setExercises(
              exercises.map((exercise) =>
                exercise.id === updatedExercise.id ? updatedExercise : exercise
              )
            );
            setShowEditForm(false);
            setCurrentExercise(null);
            setShouldFetch(true);
          }}
          onCancel={() => setShowEditForm(false)}
          onChange={handleEditFormChange}
          token={token}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="flex sm:flex-row flex-col sm:space-x-4">
          <div className="sm:w-1/3 mb-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <input
              type="text"
              id="search"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by Name"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="sm:w-1/3 mb-4">
            <label htmlFor="sort" className="sr-only">
              Sort
            </label>
            <select
              id="sort"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={sortOrder}
              onChange={(e) => handleSortChange(e.target.value)}
            >
              <option value="asc">Name (A-Z)</option>
              <option value="desc">Name (Z-A)</option>
              <option value="oldest">Oldest</option>
              <option value="newest">Newest</option>
            </select>
          </div>
          <div className="mt-4 mb-6 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
              onClick={() => setShowAddForm(true)}
            >
              <FaPlusCircle className="mr-2" />
              Add Exercise
            </button>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <Table
            headers={headers}
            data={currentExercises}
            onEdit={handleEditClick}
            onDelete={deleteExercise}
            renderRow={renderRow}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Exercises;
