import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import { FaPlusCircle } from "react-icons/fa";
import EditForm from "./EditForm";
import AddForm from "./AddForm";
import Loader from "../../../components/Loader/Loader";
import Table from "../../../components/Table/Table";

const Workouts = () => {
  const [workouts, setWorkouts] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    const fetchData = async () => {
      if (shouldFetch || workouts.length === 0) {
        await fetchWorkouts();
        setShouldFetch(false);
      }
    };

    fetchData();
  }, [shouldFetch]);

  const fetchWorkouts = async () => {
    setLoading(true);

    try {
      const response = await api.get("/admin/workouts?paginate=1000", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("get", response.data.data);
      setWorkouts(response.data.data);
    } catch (error) {
      console.log("Error fetching workouts");
      // alert("Error fetching workouts");
    } finally {
      setLoading(false);
    }
  };

  const addWorkout = async (workout) => {
    try {
      const response = await api.post("/admin/workouts", workout, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setWorkouts([...workouts, response.data]);
      setShowAddForm(false);
      setShouldFetch(true);
      return response;
    } catch (error) {
      // console.error("Error adding Workout:", error);
      alert(`Error adding Workout`);
    }
  };

  const updateWorkout = async (updatedWorkout) => {
    if (!updatedWorkout.id) {
      alert(`Error: Updated Workout does not have an ID`);

      return;
    }

    try {
      setEditing(true);
      const response = await api.post(
        `/admin/workouts/${updatedWorkout.id}`,
        updatedWorkout,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setWorkouts(
        workouts.map((workout) =>
          workout.id === updatedWorkout.id ? updatedWorkout : workout
        )
      );
      setShowEditForm(false);
      setCurrentWorkout(null);
      setShouldFetch(true);

      return response;
    } catch (error) {
      // console.error("Error updating Workout:", error);
      alert(`Error updating Workout`);
    } finally {
      setEditing(false);
    }
  };

  const handleSave = (updatedWorkout) => {
    // console.log("Updating workout with ID:", updatedWorkout.id);
    updateWorkout(updatedWorkout);
  };

  const deleteWorkout = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Do you want to delete this workouts?"
      );
      if (!isConfirmed) {
        return;
      }

      await api.delete(`/admin/workouts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShouldFetch(true);
    } catch (error) {
      // console.error("Error deleting Workout:", error);
      alert(`Error deleting Workout`);
    }
  };

  const handleEditClick = (workout) => {
    setCurrentWorkout(workout);
    setShowEditForm(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentWorkout({ ...currentWorkout, [name]: value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const getSortedWorkouts = (workouts) => {
    let sortedWorkouts = [...workouts];

    if (sortOrder === "asc") {
      sortedWorkouts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOrder === "desc") {
      sortedWorkouts.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortOrder === "oldest") {
      sortedWorkouts.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedWorkouts.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedWorkouts;
  };

  // Filter workouts based on search query
  const filteredWorkouts = getSortedWorkouts(
    workouts.filter((workout) => {
      if (workout.title && typeof workout.title === "string") {
        return workout.title.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredWorkouts.length / itemsPerPage);
  const paginatedWorkouts = filteredWorkouts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const headers = ["Title", "Description", "Tags", "Exercises"];

  const renderRow = (workout) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {workout.title ? workout.title : ""}
      </td>
      <td className="max-w-[35px] break-words overflow-hidden text-ellipsis px-6 py-4 text-sm text-gray-500">
        {workout.description ? workout.description : ""}
      </td>

      <td className="px-4 py-2">
        {workout.tags && workout.tags.length > 0
          ? workout.tags.map((tag, index) => (
              <div key={index}>
                <span className="font-semibold">
                  {tag.name ? tag.name : ""}
                </span>
              </div>
            ))
          : ""}
      </td>
      <td className="px-4 py-2">
        {workout.exercises && workout.exercises.length > 0
          ? workout.exercises.map((exercise, index) => (
              <div key={index}>
                <span className="font-semibold">
                  {exercise.name ? exercise.name : ""}
                </span>
              </div>
            ))
          : ""}
      </td>
    </>
  );

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Workout Dashboard
          </h1>
        </div>
      </div>

      {showAddForm && (
        <AddForm onSave={addWorkout} onCancel={() => setShowAddForm(false)} />
      )}
      {showEditForm && (
        <EditForm
          formData={currentWorkout}
          onSave={handleSave}
          onCancel={() => setShowEditForm(false)}
          onChange={handleEditFormChange}
          editing={editing}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="flex sm:flex-row flex-col sm:space-x-4">
          <div className="sm:w-1/3 mb-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <input
              type="text"
              id="search"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by Title"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="sm:w-1/3 mb-4">
            <label htmlFor="sort" className="sr-only">
              Sort
            </label>
            <select
              id="sort"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={sortOrder}
              onChange={(e) => handleSortChange(e.target.value)}
            >
              <option value="asc">Title (A-Z)</option>
              <option value="desc">Title (Z-A)</option>
              <option value="oldest">Oldest</option>
              <option value="newest">Newest</option>
            </select>
          </div>
          <div className="mt-4 mb-6 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
              onClick={() => setShowAddForm(true)}
            >
              <FaPlusCircle className="mr-2" />
              Add Workout
            </button>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <Table
            headers={headers}
            data={paginatedWorkouts}
            onEdit={handleEditClick}
            onDelete={deleteWorkout}
            renderRow={renderRow}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Workouts;
