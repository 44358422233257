import React, { useState } from "react";

const AddForm = ({ onSave, onCancel }) => {
  const initialFormData = {
    title_en: "",
    title_ar: "",
    instructions_en: "",
    instructions_ar: "",
    meal_type: "",
    nutrition_data: { calories: "", carbs: "", protein: "", fats: "" },
    tags: [],
    ingredients: [],
    images: [],
    // videos: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);
  // const [previewVideos, setPreviewVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in formData.nutrition_data) {
      setFormData({
        ...formData,
        nutrition_data: {
          ...formData.nutrition_data,
          [name]: value,
        },
      });
    } else if (name.startsWith("tag_")) {
      const index = parseInt(name.split("_")[1]);
      const newTags = [...formData.tags];
      newTags[index] = value;
      setFormData({ ...formData, tags: newTags });
    } else if (name.startsWith("ingredient_")) {
      const [_, index, field, subField] = name.split("_"); // Updated to handle subFields like 'name_ar'
      const newIngredients = [...formData.ingredients];

      if (field === "name" && subField) {
        newIngredients[parseInt(index)].name[subField] = value;
      } else {
        newIngredients[parseInt(index)][field] = value;
      }

      setFormData({ ...formData, ingredients: newIngredients });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddTag = () => {
    setFormData({
      ...formData,
      tags: [...formData.tags, ""],
    });
  };

  const handleRemoveTag = (index) => {
    const newTags = formData.tags.filter((_, i) => i !== index);
    setFormData({ ...formData, tags: newTags });
  };

  const handleAddIngredient = () => {
    setFormData({
      ...formData,
      ingredients: [
        ...formData.ingredients,
        { name: { ar: "", en: "" }, quantity: "" },
      ],
    });
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...formData.ingredients];
    newIngredients.splice(index, 1);
    setFormData({ ...formData, ingredients: newIngredients });
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    // Update formValues with new files
    setFormData((prevValues) => ({
      ...prevValues,
      [type]: files,
    }));

    // Generate previews for newly added files
    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    if (type === "images") {
      setPreviewImages(previews);
    }
    // else if (type === "videos") {
    //   setPreviewVideos(previews);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!formData.ingredients || formData.ingredients.length === 0) {
      window.alert("You should add ingredients");
      return;
    }

    // Log the ingredients to debug
    // console.log("Ingredients before validation:", formData.ingredients);

    // Check if tags are defined
    const validTags = formData.tags.filter((tag) => tag && tag.trim() !== "");

    // Check if ingredients are defined and each ingredient has a name and quantity
    const validIngredients = formData.ingredients.filter((ingredient) => {
      // Check if any name field is filled
      const hasName =
        ingredient.name &&
        ((ingredient.name.en && ingredient.name.en.trim() !== "") ||
          (ingredient.name.ar && ingredient.name.ar.trim() !== ""));
      const hasQuantity =
        ingredient.quantity && ingredient.quantity.trim() !== "";
      return hasName && hasQuantity;
    });

    // Log the valid ingredients to debug
    // console.log("Valid Ingredients:", validIngredients);

    if (validIngredients.length === 0) {
      alert("Each ingredient must have a name and quantity.");
      return;
    }
    setLoading(true);
    await onSave({
      ...formData,
      tags: validTags,
      ingredients: validIngredients,
    });
    setPreviewImages([]);
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-12 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 space-y-6 max-h-[80vh] overflow-y-auto custom-scrollbar"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="title_en"
                  name="title_en"
                  type="text"
                  minLength="2"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formData.title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="title_ar"
                  name="title_ar"
                  type="text"
                  minLength="2"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formData.title_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Meal Type
              </h2>
              <select
                id="meal_type"
                name="meal_type"
                required
                placeholder="select"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={formData.meal_type}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Meal Type
                </option>
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Dinner">Dinner</option>
                <option value="Snack">Snack</option>
              </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Calories
                </h2>
                <input
                  id="calories"
                  name="calories"
                  type="number"
                  min={0.1}
                  step="any"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Calories"
                  defaultValue={formData.nutrition_data.calories}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Carbs
                </h2>
                <input
                  id="carbs"
                  name="carbs"
                  type="number"
                  min={0.1}
                  step="any"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Carbs"
                  defaultValue={formData.nutrition_data.carbs}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Protein
                </h2>
                <input
                  id="protein"
                  name="protein"
                  type="number"
                  min={0.1}
                  step="any"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Protein"
                  defaultValue={formData.nutrition_data.protein}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Fats
                </h2>
                <input
                  id="fats"
                  name="fats"
                  type="number"
                  min={0.1}
                  step="any"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Fats"
                  defaultValue={formData.nutrition_data.fats}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Tags
              </h2>
              {formData.tags.map((tag, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`tag_${index}`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Tag Name"
                    defaultValue={tag.name}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveTag(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddTag}
              >
                Add Tag
              </button>
            </div>
            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Ingredients
              </h2>
              {formData.ingredients.map((ingredient, index) => (
                <div key={index} className="flex-col  items-center space-x-2">
                  <h3 className="text-white text-start text-xl my-5 font-semibold">
                    Ingredient {index + 1} (English)
                  </h3>
                  <input
                    type="text"
                    name={`ingredient_${index}_name_en`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Name (English)"
                    defaultValue={ingredient.name.en}
                    required
                    onChange={handleInputChange}
                  />{" "}
                  <h3 className="text-white text-start text-xl my-5 font-semibold mt-2">
                    Ingredient {index + 1} (Arabic)
                  </h3>
                  <input
                    type="text"
                    name={`ingredient_${index}_name_ar`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Name (Arabic)"
                    defaultValue={ingredient.name.ar}
                    required
                    onChange={handleInputChange}
                  />{" "}
                  <h3 className="text-white text-start text-xl my-5 font-semibold">
                    Quantity
                  </h3>
                  <input
                    type="text"
                    name={`ingredient_${index}_quantity`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Quantity"
                    defaultValue={ingredient.quantity}
                    required
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 my-3 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveIngredient(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddIngredient}
              >
                Add Ingredient
              </button>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Instructions
              </h2>
              <textarea
                id="instructions_en"
                name="instructions_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formData.instructions_en}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Instructions
              </h2>
              <textarea
                id="instructions_ar"
                name="instructions_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formData.instructions_ar}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Images
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "images")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* {errorMessage && (
            <p className="text-red-600 font-medium text-center py-2 rounded-lg border-solid border-[2px] border-red-600 mr-5">
              Something went wrong!
            </p>
          )} */}
          <div className="flex justify-end mx-5 space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
