import React from "react";

const CardDataStats = ({ title, total, children }) => {
  return (
    <div className="flex flex-col items-center gap-3 rounded-lg border border-gray-300 bg-white py-6 px-8 shadow-lg">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
        {children}
      </div>
      <h4 className="text-2xl font-bold text-gray-800">{total}</h4>
      <p className="text-md font-medium text-gray-600">{title}</p>
    </div>
  );
};

export default CardDataStats;
