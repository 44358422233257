import React, { useState } from "react";
import api from "../../../../api/api";

const AddPlan = ({ onCancel, token, refresh }) => {
  const initialFormData = {
    id: null,
    name_en: "",
    name_ar: "",
    description_ar: "",
    description_en: "",
    features_detail_en: "",
    features_detail_ar: "",
    price: "",
    syr_price: "",
    old_price: "",
    old_syr_price: "",
    invoice_period: "",
    plan_type: "",
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await api.post(`/admin/plans`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      refresh();
      onCancel();
    } catch (error) {
      // console.error("Error updating plan:", error);
      alert(`Error updating plan`);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-10 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6 custom-scrollbar"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Name
                </h2>
                <input
                  id="name_en"
                  name="name_en"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="English Name"
                  defaultValue={formValues.name_en}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Name
                </h2>
                <input
                  id="name_ar"
                  name="name_ar"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Arabic Name"
                  defaultValue={formValues.name_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Description
              </h2>
              <textarea
                id="description_en"
                name="description_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.description_en}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Description
              </h2>
              <textarea
                id="description_ar"
                name="description_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.description_ar}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Details
              </h2>
              <textarea
                id="features_detail_en"
                name="features_detail_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.features_detail_en}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Details
              </h2>
              <textarea
                id="features_detail_ar"
                name="features_detail_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.features_detail_ar}
                onChange={handleInputChange}
              />
            </div>

            {/* Add other form fields like plan_type, price, syr_price, etc. */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Months Count
                </h2>
                <input
                  id="invoice_period"
                  name="invoice_period"
                  type="number"
                  min={1}
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Invoice Period"
                  defaultValue={formValues.invoice_period}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Plan Type
                </h2>
                <select
                  id="plan_type"
                  name="plan_type"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  defaultValue={formValues.plan_type}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Plan Type
                  </option>
                  <option value="Standard">Standard</option>
                  <option value="Most Popular">Most Popular</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Price
                </h2>
                <input
                  id="price"
                  name="price"
                  type="number"
                  min={1}
                  step="any"
                  pattern="\d+(\.\d{1,2})?"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Price"
                  defaultValue={formValues.price}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Old Price
                </h2>
                <input
                  id="old_price"
                  name="old_price"
                  type="number"
                  min={1}
                  step="any"
                  pattern="\d+(\.\d{1,2})?"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Old Price"
                  defaultValue={formValues.old_price}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Syrian Price
                </h2>
                <input
                  id="syr_price"
                  name="syr_price"
                  type="number"
                  min={1}
                  step="any"
                  pattern="\d+(\.\d{1,2})?"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Syrian Price"
                  defaultValue={formValues.syr_price}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Old Syrian Price
                </h2>
                <input
                  id="old_syr_price"
                  name="old_syr_price"
                  type="number"
                  min={1}
                  step="any"
                  pattern="\d+(\.\d{1,2})?"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Old Syrian Price"
                  defaultValue={formValues.old_syr_price}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4 pr-5">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPlan;
